import React, { useEffect } from "react";
import "./Card.css"; // Arquivo CSS para estilização
import Icon1 from '../assets/logo-dominus.png';
import Icon2 from '../assets/coach-icon.png';
import Icon3 from '../assets/gift-icon.png';

const Card = ({ iconSrc, title, description, buttonText, link }) => {
  // Função para redirecionar o usuário ao clicar no botão
  const handleButtonClick = (event) => {
    event.preventDefault(); // Previne o comportamento padrão do link
    window.open(link, "_blank", "noopener,noreferrer"); // Abre o link em uma nova aba de forma segura
  };

  return (
    <div className="card">
      <div className="card-icon">
        <img src={iconSrc} alt={title} />
      </div>
      <h3 className="card-title">{title}</h3>
      <p
        className="card-description"
        dangerouslySetInnerHTML={{ __html: description }}
      ></p>
      {buttonText && (
        <a
          href={link}
          target="_blank"
          rel="noopener noreferrer"
          className="card-button"
        >
          {buttonText}
        </a>
      )}
    </div>
  );
};

const CardsContainer = () => {
  const cardsData = [
    {
      iconSrc: Icon1,
      title: "CURSO DOMINUS",
      description:
        "Curso COMPLETO de VALORANT, fundamentos básicos até o mais avançado.<br/><br/>" +
        "Bônus: Além do conteúdo principal, você receberá 4 aulas extras aprofundadas sobre as funções do jogo: Duelista, Iniciador, Controlador e Sentinela.<br/><br/>" +
        "<div class='card-price'>" +
        "Oferta especial de: <span class='old-price'>R$ 178,00</span>" +
        " por <span class='new-price'>R$ 87,90</span>" +
        "</div>",
    },
    {
      iconSrc: Icon2,
      title: "VOD REVIEW",
      description:
        "Receba uma análise detalhada da sua partidas feita por nossa equipe.<br/>" +
        "Nossa equipe inclui coaches experientes que atuam em campeonatos oficiais da Riot Games, como o VCT Game Changers. Tenha a oportunidade de melhorar sua gameplay com orientações práticas e personalizadas para elevar seu nível de jogo!",
      buttonText: "EU QUERO",
      link: "https://pay.kiwify.com.br/Enr2X4f",
    },
    {
      iconSrc: Icon3,
      title: "PREMIAÇÕES DOMINUS",
      description:
        "Ao se tornar aluno da Dominus Academy.<br/><br/>" +
        "Você terá acesso a sorteios mensais exclusivos que dão dinheiro ou um periférico a sua escolha e também poderá participar de nossos campeonatos trimestrais, abertos para todos os elos.<br/>" +
        "Esses torneios oferecem premiações incríveis, incluindo dinheiro e VP!",
    },
  ];

  useEffect(() => {
    // Pixel de rastreamento já carregado no index.html
  }, []);

  return (
    <div data-aos="fade" className="cards-container">
      {cardsData.map((card, index) => (
        <Card
          key={index}
          iconSrc={card.iconSrc}
          title={card.title}
          description={card.description}
          buttonText={index === 1 ? card.buttonText : null} // Botão só no card do meio
          link={card.link}
        />
      ))}
    </div>
  );
};

export default CardsContainer;
