import React from 'react';
import './Faqs.css'; // Certifique-se de criar um arquivo CSS separado para os estilos

const FAQComponent = () => {
  return (
    <div className="faq-container">
      <div className="faq-left">
        <h2>FAQS</h2>
        <p>
          Se ainda tiver alguma dúvida, você pode enviar um email para a gente ou até mesmo uma mensagem no nosso WhatsApp, nosso atendimento é 24/7.
        </p>
        <div className="contact">
          <div className="email">
            <p><strong>EMAIL</strong></p>
            <a href="mailto:academydominus@gmail.com">academydominus@gmail.com</a>
          </div>
          <div className="whatsapp">
            <p><strong>WHATSAPP</strong></p>
            <a href="https://wa.me/5579996266625" target="_blank" rel="noopener noreferrer">(79) 99626-6625</a>
          </div>
        </div>
      </div>

      <div className="faq-right">
        <h3>O QUE VOU APRENDER NO CURSO?</h3>
        <p>No curso da Dominus, você aprenderá tudo o que precisa para evoluir no VALORANT, desde as bases fundamentais até técnicas avançadas.</p>

        <h3>SUA EVOLUÇÃO EM 7 DIAS OU SEU DINHEIRO DE VOLTA</h3>
        <p>
          Caso você não fique satisfeito com o nosso conteúdo, você pode pedir a garantia nos primeiros 7 dias e, assim que solicitado, seu reembolso é processado automaticamente pela Kiwify.
          Prezamos sempre pela qualidade e satisfação dos nossos alunos.
        </p>

        <h3>O CURSO É POR MENSALIDADE?</h3>
        <p>
          Não, os PRIMEIROS 100 alunos do nosso curso receberão acesso único e vitalício, onde você paga apenas UMA vez e tem acesso VITALÍCIO aos conteúdos nele que serão atualizados.
        </p>

        <h3>ONDE VEJO AS AULAS?</h3>
        <p>
          Após ser realizado o pagamento, será enviado no seu e-mail a confirmação e o acesso ao curso.
        </p>
      </div>
    </div>
  );
};

export default FAQComponent;
