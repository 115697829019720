import React from "react";
import "./Promobanner.css";

const PromoBanner = () => {
  return (
    <div className="promo-banner">
      <h1 className="promo-title">
        SEJA UM DOS 100 PRIMEIROS A TER ACESSO <br />
        EXCLUSIVO E VITALÍCIO AO NOSSO CURSO!
      </h1>
      <p className="promo-description">
        "Garanta agora mesmo seu acesso com pagamento único e aproveite todos os
        conteúdos, que serão constantemente atualizados para você se manter à
        frente do jogo."
      </p>
      <a 
        href="https://pay.kiwify.com.br/3MpnOmc"
        target="_blank" 
        rel="noopener noreferrer" 
        className="promo-button"
      >
        SEJA DOMINUS
      </a>
    </div>
  );
};

export default PromoBanner;
